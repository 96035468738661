class Globals {

    constructor() {

        const mobileButton = document.getElementById('mobileButton');
        const mobileClose = document.getElementById('mobileClose');

        window.addEventListener('resize', () => {
            if(window.innerWidth > 1023) {
                this.closeMenu();
            }
        }, true);

        //Attach event listener to close button.
        mobileClose.addEventListener("click", e => {
            this.closeMenu();
        });

        //Attach event listener to hamburger.
        mobileButton.addEventListener("click", e => {
            this.openMenu();
        });

    }

    openMenu() {
        const body = document.querySelector('body');
        body.classList.add('active');
    }

    closeMenu() {
        const body = document.querySelector('body');
        body.classList.remove('active');
    }
}

export default Globals;